import React from "react";
import classes from './TermsOrRefer.module.scss';
import { useTranslation, Trans } from "react-i18next";

const TermsOrRefer = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.whatIs}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    <Trans>promo_57</Trans>
                </h2>
                <div className={classes.contentWrap}>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <p>
                                    {t("promo_58")}
                                </p>
                                <p>
                                    {t("promo_59")}
                                </p>
                                <p>
                                    {t("promo_60")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <ul>
                                    <li>
                                        {t("promo_61")}
                                    </li>
                                    <li>
                                        {t("promo_62")}
                                    </li>
                                    <li>
                                        {t("promo_63")}
                                    </li>
                                </ul>
                                <p>
                                    {t("promo_64")}
                                </p>
                                <p>
                                    {t("promo_65")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default TermsOrRefer;
