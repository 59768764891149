import React from "react";
import classes from "./Tab1.module.scss";
import img11 from "../../../../assets/img/HomePage/1/1/1.png";
import img12 from "../../../../assets/img/HomePage/1/1/2.png";
import img13 from "../../../../assets/img/HomePage/1/1/3.png";
import img14 from "../../../../assets/img/HomePage/1/1/4.png";
import img15 from "../../../../assets/img/HomePage/1/1/5.png";
import img1Mob from "../../../../assets/img/HomePage/1/1/mob.png";

const Tab1 = ({ active }) => {
  return (
    <div
      className={
        active
          ? [classes.firstRowRightImgs1, classes.active, ""].join(" ")
          : [classes.firstRowRightImgs1, ""].join(" ")
      }
    >
      <div className={[classes.firstRowRightImgs1Img1, ""].join(" ")}>
        <img src={img11} alt="Join the Fastest Growing Forex Community!" />
      </div>
      <div className={[classes.firstRowRightImgs1Img2, ""].join(" ")}>
        <img src={img12} alt="Join the Fastest Growing Forex Community!" />
      </div>
      <div className={[classes.firstRowRightImgs1Img3, ""].join(" ")}>
        <img src={img13} alt="Join the Fastest Growing Forex Community!" />
      </div>
      <div className={[classes.firstRowRightImgs1Img4, ""].join(" ")}>
        <img src={img14} alt="Join the Fastest Growing Forex Community!" />
      </div>
      <div className={[classes.firstRowRightImgs1Img5, ""].join(" ")}>
        <img src={img15} alt="Join the Fastest Growing Forex Community!" />
      </div>
      <div className={[classes.imgMob, ""].join(" ")}>
        <img src={img1Mob} alt="" />
      </div>
    </div>
  );
};

export default Tab1;
