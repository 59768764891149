import React from "react";
import classes from './Information.module.scss';
import { useTranslation } from "react-i18next";
import image from '../../../assets/img/EducationPage/Information/image.png';

const Information = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.information}>
                <div className={classes.content}>
                    <h2 className={`${classes.title} font-40`}>
                        {t('education_title_02')}
                    </h2>
                    <div className={`${classes.text} font-20`}>
                        <p>
                            {t('education_text_01')}
                        </p>
                        <p>
                            {t('education_text_02')}
                        </p>
                        <p>
                            {t('education_text_03')}
                        </p>
                        <p>
                            {t('education_text_04')}
                        </p>
                        <p>
                            {t('education_text_05')}
                        </p>
                    </div>
                </div>
                <img className={classes.image} src={image} alt=''/>
            </div>
        </section>
    );
};

export default Information;
