import React from "react";
import classes from './Banner.module.scss';
import { useTranslation, Trans } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";
import bg from '../../../assets/img/TradingPage/Banner/bg.png';
import image_01 from '../../../assets/img/TradingPage/Banner/image_01.png';
import image_02 from '../../../assets/img/TradingPage/Banner/image_02.png';
import image_03 from '../../../assets/img/TradingPage/Banner/image_03.png';

const Banner = () => {
    const { t } = useTranslation();
    return (
        <section className={classes.bannerWrap}>
            <img className={classes.bg} src={bg} alt=''/>
            <div className="container">
                <div className={classes.banner}>
                    <div className={classes.content}>
                        <h1 className={[classes.title, 'font-40'].join(' ')}>
                            <Trans>main_86</Trans>
                        </h1>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t("main_87")}
                        </p>
                        <div className={classes.btn}>
                            <Button>{t("btn_trade")}</Button>
                        </div>
                    </div>
                    <div className={[classes.image, classes.image_01].join(' ')}>
                        <img src={image_01} alt=''/>
                    </div>
                    <div className={[classes.image, classes.image_02].join(' ')}>
                        <img src={image_02} alt=''/>
                    </div>
                    <div className={[classes.image, classes.image_03].join(' ')}>
                        <img src={image_03} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Banner;
