import React from "react";
import classes from './EarnPerFriend.module.scss';
import image_01 from '../../../assets/img/PromotionsPage/EarnPerFriend/image_01.png';
import image_02 from '../../../assets/img/PromotionsPage/EarnPerFriend/image_02.png';
import image_03 from '../../../assets/img/PromotionsPage/EarnPerFriend/image_03.png';
import { Trans, useTranslation } from "react-i18next";

const EarnPerFriend = () => {
    const { t } = useTranslation();
    const cards = [
        {
            image: image_01,
            title: 'promo_43',
            text: 'promo_44',
        },
        {
            image: image_02,
            title: 'promo_45',
            text: 'promo_46',
        },
        {
            image: image_03,
            title: 'promo_47',
            text: 'promo_48',
        },
    ];
    return (
        <section className="container mt">
            <div className={classes.earnPerFriend}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t("promo_49")}
                </h2>
                <h3 className={[classes.subtitle, 'font-40'].join(' ')}>
                    <span>
                        {t("promo_50")}
                    </span>
                </h3>
                <p className={[classes.text, 'font-20'].join(' ')}>
                    {t("promo_51")}
                </p>
                <div className={classes.cards}>
                    {cards.map((card, index) =>
                        <div key={index} className={[classes.card, 'font-20'].join(' ')}>
                            <img className={classes.cardImage} src={card.image} alt=''/>
                            <h4 className={classes.cardTitle}>
                                <Trans>
                                    {card.title}
                                </Trans>
                            </h4>
                            <p className={classes.cardText}>
                                <Trans>
                                    {card.text}
                                </Trans>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
};

export default EarnPerFriend;
