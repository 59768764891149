import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/LearnComponents/First/First";
import Second from "../../components/LearnComponents/Second/Second";
import ChooseAccount from "../../components/LearnComponents/ChooseAccount/ChooseAccount";
import Three from "../../components/LearnComponents/Three/Three";
import Eight from "../../components/LearnComponents/Eight/Eight";
import Faq from "../../components/LearnComponents/Faq/Faq";

const LearnPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Learn To Trade</title>
        <meta
          name="title"
          content="Learn Forex Trading from Experts - Leverus"
        />
        <meta
          property="og:title"
          content="Learn Forex Trading from Experts - Leverus"
        />
        <meta
          property="twitter:title"
          content="Learn Forex Trading from Experts - Leverus"
        />

        <meta
          name="description"
          content="Whether you are a beginner or an experienced trader, Leverus offers a wide range of educational resources to help you improve your trading skills. Join our comprehensive training programs and webinars to become a successful trader."
        />
        <meta
          property="og:description"
          content="Whether you are a beginner or an experienced trader, Leverus offers a wide range of educational resources to help you improve your trading skills. Join our comprehensive training programs and webinars to become a successful trader."
        />
        <meta
          property="twitter:description"
          content="Whether you are a beginner or an experienced trader, Leverus offers a wide range of educational resources to help you improve your trading skills. Join our comprehensive training programs and webinars to become a successful trader."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <ChooseAccount />
        <Three />
        <Eight />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default LearnPage;
