import React, { useRef } from "react";
import classes from "./ChooseAccount.module.scss";

import arrow from "../../../assets/img/AccountTypes/ChooseAccount/arrow.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import { useTranslation, Trans } from "react-i18next";

const ChooseAccount = () => {
  const { t } = useTranslation();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const cards = [
    {
      id: 1,

      desc: t("main_38"),
      title: "main_39",
      list: [
        t("main_40"),
        t("main_41"),
      ],
      text: "main_42",
    },
    {
      id: 2,

      desc: t("main_43"),
      title: "main_44",
      list: [
        t("main_45"),
        t("main_46"),
      ],
      text: "main_47",
    },
    {
      id: 3,

      desc: t("main_48"),
      title: "main_49",
      list: [
        t("main_50"),
        t("main_51"),
      ],
      text: "main_52",
    },
    {
      id: 4,

      desc: t("main_53"),
      title: "main_54",
      list: [
        t("main_55"),
        t("main_56"),
      ],
      text: "main_57",
    },
    {
      id: 5,

      desc: t("main_58"),
      title: "main_59",
      list: [
        t("main_60"),
        t("main_61"),
      ],
      text: "main_62",
    },
  ];

  return (
    <section className={classes.chooseAccount}>
      <div className="container">
        <div className={[classes.fiveTop, ""].join(" ")}>
          <h2 className={[classes.fiveTopTitle, "font-40"].join(" ")}>
            <Trans>main_37</Trans>
          </h2>
          <p className={[classes.fiveTopText, "font-20"].join(" ")}>
            {t("main_63")}
          </p>
        </div>
      </div>
      <div className={classes.swiperWr}>
        <Swiper
          modules={[Pagination, Navigation]}
          className="chooseAccountTypeSwiper"
          spaceBetween={20}
          slidesPerView={"auto"}
          pagination={true}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onBeforeInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
          }}
        >
          {cards.map((card) => (
            <SwiperSlide key={card.id} className="chooseAccountTypeSwiperSlide">
              <div className={classes.card}>
                <div className={classes.cardContent}>
                  <h3 className={[classes.cardTitle, "font-20"].join(" ")}>
                    <Trans>{card.title}</Trans>
                  </h3>

                  <p className={[classes.cardText, "font-18"].join(" ")}>
                  <Trans>{card.text}</Trans>
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div className="container">
        <div className={classes.pagination}>
          <img ref={prevRef} className={classes.pagPrev} src={arrow} alt="" />
          <img ref={nextRef} className={classes.pagNext} src={arrow} alt="" />
        </div>
      </div>
    </section>
  );
};

export default ChooseAccount;
