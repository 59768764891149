import React from "react";
import classes from "./Three.module.scss";
import img1 from "../../../assets/img/AboutPage/4/1.png";
import {useTranslation, Trans} from "react-i18next";
const Three = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.three, "mt"].join(" ")}>
      <div className={[classes.threeBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.threeRow, ""].join(" ")}>
            <div className={[classes.threeRowLeft, ""].join(" ")}>
              <h2 className={[classes.threeRowLeftTitle, "font-40"].join(" ")}>
                <Trans>about_12</Trans>
              </h2>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t("about_13")}
              </p>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t("about_14")}
              </p>
              <p className={[classes.threeRowLeftText, "font-20"].join(" ")}>
                {t("about_15")}
              </p>
            </div>
            <div className={[classes.threeRowRight, ""].join(" ")}>
              <img src={img1} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Three;
