import HomePage from '../pages/HomePage/HomePage';
import TradingPage from '../pages/TradingPage/TradingPage';
import LearnPage from '../pages/LearnPage/LearnPage';
import PromotionsPage from '../pages/PromotionsPage/PromotionsPage';
import FaqPage from '../pages/FaqPage/FaqPage';
import AboutPage from '../pages/AboutPage/AboutPage';
import EducationPage from '../pages/EducationPage/EducationPage';

export const routes = [
    { path: '/', element: HomePage },
    { path: '/trading', element: TradingPage },
    { path: '/learn', element: LearnPage },
    { path: '/promotion', element: PromotionsPage },
    { path: '/faq', element: FaqPage },
    { path: '/about', element: AboutPage },
    { path: '/education', element: EducationPage },
    { path: '/:lang/', element: HomePage },
    { path: '/:lang/trading', element: TradingPage },
    { path: '/:lang/learn', element: LearnPage },
    { path: '/:lang/promotion', element: PromotionsPage },
    { path: '/:lang/faq', element: FaqPage },
    { path: '/:lang/about', element: AboutPage },
    { path: '/:lang/education', element: EducationPage },
];

export const headerRoutes = [
    { id: 1, path: 'trading', text: 'header_1' },
    { id: 2, path: 'learn', text: 'header_2' },
    { id: 3, path: 'promotion', text: 'header_3' },
    { id: 4, path: 'faq', text: 'header_4' },
    { id: 5, path: 'about', text: 'header_5' },
    { id: 6, path: 'education', text: "education_title" },
];
