import React from "react";
import classes from './WhatIs.module.scss';
import { useTranslation, Trans } from "react-i18next";
import phones from '../../../assets/img/PromotionsPage/WhatIs/phones.png';
import item_01 from '../../../assets/img/PromotionsPage/WhatIs/item_01.png';
import item_02 from '../../../assets/img/PromotionsPage/WhatIs/item_02.png';
import item_03 from '../../../assets/img/PromotionsPage/WhatIs/item_03.png';

const WhatIs = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.whatIs}>
                <div className={classes.col}>
                    <div className={classes.content}>
                        <h2 className={[classes.title, 'font-40'].join(' ')}>
                            <Trans>promo_19</Trans>
                        </h2>
                        <p className={[classes.text, 'font-20'].join(' ')}>
                            {t("promo_20")}
                        </p>
                    </div>
                </div>
                <div className={classes.col}>
                    <div className={classes.images}>
                        <img className={classes.phones} src={phones} alt=''/>
                        <img className={[classes.item, classes.item_01].join(' ')} src={item_01} alt=''/>
                        <img className={[classes.item, classes.item_02].join(' ')} src={item_02} alt=''/>
                        <img className={[classes.item, classes.item_03].join(' ')} src={item_03} alt=''/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default WhatIs;
