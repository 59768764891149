import React, { useEffect } from "react";
import img1 from "../../../assets/img/AboutPage/7/1.png";
import img2 from "../../../assets/img/AboutPage/7/2.png";
import img3 from "../../../assets/img/AboutPage/7/3.png";
import img4 from "../../../assets/img/AboutPage/7/4.png";
import img5 from "../../../assets/img/AboutPage/7/5.png";
import classes from "./Slider.module.scss";
import imgLeft from "../../../assets/img/HomePage/slider/left.png";
import imgRight from "../../../assets/img/HomePage/slider/right.png";
import {
  StackedCarousel,
  ResponsiveContainer,
} from "react-stacked-center-carousel";
// import { Fab } from "@material-ui/core";
// import testlefticon from "../assets/pictures/testlefticon.svg"
// import testrightiicon from "../assets/pictures/testrightiicon.svg"

import "./styles.scss";
import { Slide } from "./testimonial.js";
import {useTranslation} from "react-i18next";
// import pic1 from "../assets/pictures/our-clients/pic1.png"
// import pic2 from "../assets/pictures/our-clients/pic2.png"
// import pic3 from "../assets/pictures/our-clients/pic3.png"
// import pic4 from "../assets/pictures/our-clients/pic4.png"

const Testimonial = () => {
  const { t } = useTranslation();
  const data = [
    {
      image: img1,
      title: t("about_33"),
      text: t("about_34"),
    },
    {
      image: img2,
      title: t("about_35"),
      text: t("about_36"),
    },
    {
      image: img3,
      title: t("about_37"),
      text: t("about_38"),
    },
    {
      image: img4,
      title: t("about_39"),
      text: t("about_40"),
    },
    {
      image: img5,
      title: t("about_41"),
      text: t("about_42"),
    },
  ];
  const ref = React.useRef(StackedCarousel);

  return (
    <div className={[classes.slider, "mt"].join(" ")}>
      <div className="card card-carrier">
        <div style={{ position: "relative" }}>
          <ResponsiveContainer
            carouselRef={ref}
            render={(width, carouselRef) => {
              let currentVisibleSlide = 3;
              if (width <= 1280) currentVisibleSlide = 3;
              if (width <= 720) currentVisibleSlide = 1;
              return (
                <StackedCarousel
                  ref={carouselRef}
                  slideComponent={Slide}
                  slideWidth={width > 1220 ? 750 : 500}
                  carouselWidth={width}
                  data={data}
                  maxVisibleSlide={3}
                  customScales={[
                    1, 0.65,

                    0.8,
                  ]}
                  transitionTime={450}
                  currentVisibleSlide={currentVisibleSlide}
                />
              );
            }}
          />
          <div className={[classes.arrows, ""].join(" ")}>
            <div
              className={[classes.arrowsLeft, ""].join(" ")}
              onClick={() => ref.current?.goBack()}
            >
              <img src={imgLeft} alt="" />
            </div>
            <div
              className={[classes.arrowsRight, ""].join(" ")}
              onClick={() => ref.current?.goNext()}
            >
              <img src={imgRight} alt="" />
            </div>
          </div>

          {/* <div
          className="card-button testimonial-left-button"
          size="small"
          onClick={() => ref.current?.goBack()}
        >
          left
        </div>
        <div
          className="card-button testimonial-right-button"
          size="small"
          onClick={() => ref.current?.goNext()}
        >
          right
        </div> */}
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
