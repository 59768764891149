import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import First from "../../components/MainComponents/First/First";
import Second from "../../components/MainComponents/Second/Second";
import SixReasons from "../../components/MainComponents/SixReasons/SixReasons";
import Three from "../../components/MainComponents/Three/Three";
import Four from "../../components/MainComponents/Four/Four";
import ChooseAccount from "../../components/MainComponents/ChooseAccount/ChooseAccount";
// import Six from "../../components/MainComponents/Six/Six";
import Faq from "../../components/MainComponents/Faq/Faq";

const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Leverus</title>
        <meta
          name="title"
          content="Trade with Confidence with Leverus Forex Broker"
        />
        <meta
          property="og:title"
          content="Trade with Confidence with Leverus Forex Broker"
        />
        <meta
          property="twitter:title"
          content="Trade with Confidence with Leverus Forex Broker"
        />

        <meta
          name="description"
          content="Discover the benefits of trading with Leverus, a reliable and transparent forex broker. Join us and access a wide range of financial instruments, top-notch trading platforms, and exceptional customer service."
        />
        <meta
          property="og:description"
          content="Discover the benefits of trading with Leverus, a reliable and transparent forex broker. Join us and access a wide range of financial instruments, top-notch trading platforms, and exceptional customer service."
        />
        <meta
          property="twitter:description"
          content="Discover the benefits of trading with Leverus, a reliable and transparent forex broker. Join us and access a wide range of financial instruments, top-notch trading platforms, and exceptional customer service."
        />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <First />
        <Second />
        <SixReasons />
        <Three />
        <Four />
        <ChooseAccount />
        {/* <Six /> */}
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default HomePage;
