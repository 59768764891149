import React from "react";
import classes from './HowDo.module.scss';
import { useTranslation, Trans } from "react-i18next";
import image_01 from '../../../assets/img/PromotionsPage/HowDo/image_01.png';
import image_02 from '../../../assets/img/PromotionsPage/HowDo/image_02.png';

const HowDo = () => {
    const { t } = useTranslation();
    return (
        <section className="container mt">
            <div className={classes.whatIs}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    <Trans>promo_21</Trans>
                </h2>
                <div className={classes.contentWrap}>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <p>
                                    {t("promo_22")}
                                </p>
                                <p>
                                    {t("promo_23")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.col}>
                        <div className={classes.content}>
                            <div className={[classes.text, 'font-20'].join(' ')}>
                                <p>
                                    {t("promo_24")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={classes.plate}>
                    <img className={classes.image_01} src={image_01} alt=''/>
                    <img className={classes.image_02} src={image_02} alt=''/>
                    <div className={classes.plateContent}>
                        <div className={[classes.item, 'font-40'].join(' ')}>
                            <p>
                                500
                            </p>
                            <p className="font-20">
                                {t("promo_25")}
                            </p>
                        </div>
                        <div className={[classes.item, 'font-40'].join(' ')}>
                            <p>
                                х
                            </p>
                        </div>
                        <div className={[classes.item, 'font-40'].join(' ')}>
                            <p>
                                $10
                            </p>
                            <p className="font-20">
                                {t("promo_26")}
                            </p>
                        </div>
                        <div className={[classes.item, 'font-40'].join(' ')}>
                            <p>
                                =
                            </p>
                        </div>
                        <div className={[classes.item, 'font-40'].join(' ')}>
                            <p>
                                $5k
                            </p>
                            <p className="font-20">
                                {t("promo_27")}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowDo;
