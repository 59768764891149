import React, { useEffect, useRef } from "react";
import classes from './NeedHelp.module.scss';
import { useTranslation } from "react-i18next";
import Button from "../../GlobalComponents/Button/Button";

const NeedHelp = () => {
    const { t } = useTranslation();
    return (
        <section className="mt container">
            <div className={classes.needHelp}>
                <div className={classes.content}>
                    <h2 className={[classes.title, 'font-40'].join(' ')}>
                        {t("promo_193")}
                    </h2>
                    <p className={[classes.text, 'font-20'].join(' ')}>
                        {t("promo_194")}
                    </p>
                    <div className={classes.btn}>
                        <Button>
                            {t("btn_contact")}
                        </Button>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default NeedHelp;
