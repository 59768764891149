import React from "react";
import classes from "./Second.module.scss";
import {useTranslation} from "react-i18next";
const Second = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.second, "mt mb"].join(" ")}>
      <div className={[classes.secondBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.secondRow, ""].join(" ")}>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <h3
                className={[classes.secondRowColumnTitle, "font-60"].join(" ")}
              >
                1000+
              </h3>
              <p
                className={[classes.secondRowColumnSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("main_11")}
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <h3
                className={[classes.secondRowColumnTitle, "font-60"].join(" ")}
              >
                24/7
              </h3>
              <p
                className={[classes.secondRowColumnSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("main_12")}
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <h3
                className={[classes.secondRowColumnTitle, "font-60"].join(" ")}
              >
                1000:1
              </h3>
              <p
                className={[classes.secondRowColumnSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("main_13")}
              </p>
            </div>
            <div className={[classes.secondRowColumn, ""].join(" ")}>
              <h3
                className={[classes.secondRowColumnTitle, "font-60"].join(" ")}
              >
                $5,000
              </h3>
              <p
                className={[classes.secondRowColumnSubtitle, "font-20"].join(
                  " "
                )}
              >
                {t("main_14")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Second;
