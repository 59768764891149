import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/PromotionsPage/Banner/Banner";
import TermsAndConditions from "../../components/PromotionsPage/TermsAndConditions/TermsAndConditions";
import WhatIs from "../../components/PromotionsPage/WhatIs/WhatIs";
import HowDo from "../../components/PromotionsPage/HowDo/HowDo";
import HowItWorks from "../../components/PromotionsPage/HowItWorks/HowItWorks";
import EarnPerFriend from "../../components/PromotionsPage/EarnPerFriend/EarnPerFriend";
import HowWorks from "../../components/PromotionsPage/HowWorks/HowWorks";
import TermsOrRefer from "../../components/PromotionsPage/TermsOrRefer/TermsOrRefer";
import Faq from "../../components/PromotionsPage/Faq/Faq";

const PromotionsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Promotions</title>
        <meta name="title" content="Boost Your Profits with Leverus Promotions" />
        <meta property="og:title" content="Boost Your Profits with Leverus Promotions" />
        <meta property="twitter:title" content="Boost Your Profits with Leverus Promotions" />

        <meta name="description" content="Take advantage of Leverus's attractive promotions and bonuses to enhance your trading experience. Enjoy higher leverage, cashback, and other exclusive offers that can increase your profits significantly." />
        <meta property="og:description" content="Take advantage of Leverus's attractive promotions and bonuses to enhance your trading experience. Enjoy higher leverage, cashback, and other exclusive offers that can increase your profits significantly." />
        <meta property="twitter:description" content="Take advantage of Leverus's attractive promotions and bonuses to enhance your trading experience. Enjoy higher leverage, cashback, and other exclusive offers that can increase your profits significantly." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <TermsAndConditions />
        <WhatIs />
        <HowDo />
        <HowItWorks />
        <EarnPerFriend />
        <HowWorks />
        <TermsOrRefer />
        <Faq />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default PromotionsPage;
