import React from "react";
import classes from "./Six.module.scss";
import {useTranslation, Trans} from "react-i18next";

const Six = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.six, "mt"].join(" ")}>
      <div className={[classes.sixBody, ""].join(" ")}>
        <div className="container">
          <h2 className={[classes.sixTitle, "font-40"].join(" ")}>
            <Trans>
              {t("about_26")}
            </Trans>
          </h2>

          <div className={[classes.sixRow, ""].join(" ")}>
            <div className={[classes.sixRowLeft, ""].join(" ")}>
              <p className={[classes.sixRowLeftText, "font-20"].join(" ")}>
                {t("about_27")}
              </p>
              <p className={[classes.sixRowLeftText, "font-20"].join(" ")}>
                {t("about_28")}
              </p>
              <p className={[classes.sixRowLeftText, "font-20"].join(" ")}>
                {t("about_29")}
              </p>
            </div>
            <div className={[classes.sixRowRight, ""].join(" ")}>
              <p className={[classes.sixRowRightText, "font-20"].join(" ")}>
                {t("about_30")}
              </p>
              <p className={[classes.sixRowRightText, "font-20"].join(" ")}>
                {t("about_31")}
              </p>
              <p className={[classes.sixRowRightText, "font-20"].join(" ")}>
                {t("about_32")}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Six;
