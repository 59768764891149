import React from "react";
import classes from "./First.module.scss";
import Button from "../../GlobalComponents/Button/Button";
import img1 from "../../../assets/img/AboutPage/1/1.png";
import {useTranslation} from "react-i18next";
const First = () => {
  const { t } = useTranslation();
  return (
    <div className={[classes.first, ""].join(" ")}>
      <div className={[classes.firstBody, ""].join(" ")}>
        <div className="container">
          <div className={[classes.firstRow, ""].join(" ")}>
            <div className={[classes.firstRowLeft, ""].join(" ")}>
              <h2 className={[classes.firstRowLeftTitle, "font-40"].join(" ")}>
                {t("about_1")}
              </h2>
              <p className={[classes.firstRowLeftText, "font-20"].join(" ")}>
                {t("about_2")}
              </p>
              <p className={[classes.firstRowLeftText, "font-20"].join(" ")}>
                {t("about_3")}
              </p>
              <div className={[classes.firstRowLeftBtn, ""].join(" ")}>
                <Button>{t("btn_open")}</Button>
              </div>
            </div>
            <div className={[classes.firstRowRight, ""].join(" ")}>
              <div className={[classes.firstRowImg, ""].join(" ")}>
                <img src={img1} alt="About Us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default First;
