import React from "react";
import classes from './HowItWorks.module.scss';
import { Trans, useTranslation } from "react-i18next";

const HowItWorks = () => {
    const { t } = useTranslation();
    const rows = [
        [
            "promo_28",
            "promo_29",
            'promo_30',
            'promo_31',
            'promo_32',
            'promo_33',
            'promo_34',
            'promo_35',
        ],
        [
            'promo_36',
            '500',
            '$50 (500 x 0.10 = $50)',
            '$150',
            '—',
            '$150',
            '$135 (90% on $150)',
            '$45 (90% on $50)',
        ],
        [
            'promo_37',
            '2,000',
            '$200 (2,000 x 0.10 = $200)',
            '—',
            '$1,000',
            '$1,000',
            '$250 (10% on $1,000)',
            '$20 (10% on $200)',
        ],
        [
            'promo_38',
            '30',
            '$300 (30 x 10 = $300)',
            '$600',
            '$400',
            '$1,000',
            '$750 (75% on $1,000)',
            '$225 (75% on $300)',
        ],
        [
            'promo_39',
            '500',
            '$5,000 (500 x 10 = $5,000)',
            '—',
            '$2,000',
            '$2,000',
            '$1000 (50% on $2,000)',
            '$2500 (50% on $5,000)',
        ],
    ];
    return (
        <section className="container mt">
            <div className={classes.howItWorks}>
                <h2 className={[classes.title, 'font-40'].join(' ')}>
                    {t("promo_40")}
                </h2>
                <div className={[classes.text, 'font-20'].join(' ')}>
                    <p>
                        {t("promo_41")}
                    </p>
                    <p>
                        {t("promo_42")}
                    </p>
                </div>
                <div className={classes.tableWrap}>
                    <div className={classes.tableContainer}>
                        <table>
                            <tbody>
                                {rows.map((cols, rowIndex) =>
                                    <tr key={rowIndex}>
                                        {cols.map((col, colIndex) =>
                                            <td key={colIndex}>
                                                <Trans>
                                                    {col}
                                                </Trans>
                                            </td>
                                        )}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default HowItWorks;
