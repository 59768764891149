import React, { useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Header from "../../components/GlobalComponents/Header/Header";
import Footer from "../../components/GlobalComponents/Footer/Footer";
import Banner from "../../components/TradingPage/Banner/Banner";
import UnderBanner from "../../components/TradingPage/UnderBanner/UnderBanner";
import AccountTypes from "../../components/TradingPage/AccountTypes/AccountTypes";
import Tabs from "../../components/TradingPage/Tabs/Tabs";
import ExecutionPolicy from "../../components/TradingPage/ExecutionPolicy/ExecutionPolicy";
import LegendaryExecution from "../../components/TradingPage/LegendaryExecution/LegendaryExecution";
import MarketOrders from "../../components/TradingPage/MarketOrders/MarketOrders";

const TradingPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <HelmetProvider>
      <Helmet>
        <title>Trading</title>
        <meta name="title" content="Trade with the Best Forex Broker - Leverus" />
        <meta property="og:title" content="Trade with the Best Forex Broker - Leverus" />
        <meta property="twitter:title" content="Trade with the Best Forex Broker - Leverus" />

        <meta name="description" content="Trade Forex, Cryptocurrencies, Stocks, Indices, and more with Leverus. Benefit from our tight spreads, fast execution, and advanced trading tools to maximize your profits." />
        <meta property="og:description" content="Trade Forex, Cryptocurrencies, Stocks, Indices, and more with Leverus. Benefit from our tight spreads, fast execution, and advanced trading tools to maximize your profits." />
        <meta property="twitter:description" content="Trade Forex, Cryptocurrencies, Stocks, Indices, and more with Leverus. Benefit from our tight spreads, fast execution, and advanced trading tools to maximize your profits." />

        <meta property="og:image" content="preview.png" />
        <meta property="og:type" content="website" />
        <meta property="twitter:image" content="preview.png" />
        <meta property="twitter:card" content="summary_large_image" />
      </Helmet>
      <div className="ovf-hidden">
        <Header />
        <Banner />
        <UnderBanner />
        <AccountTypes />
        <Tabs />
        <ExecutionPolicy />
        <LegendaryExecution />
        <MarketOrders />
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default TradingPage;
